import { Site } from "wordparrot-types";
import { firstValueFrom } from "rxjs";
import { useQuery } from "react-query";

import { ApiResponse } from "lib/api";
import { QueryOptions } from "lib/request";
import { siteService } from "state/site/service";

export const useFetchSite = (
  payload?: {
    siteId: string;
  },
  options?: QueryOptions<
    ["siteService.fetchSite", typeof payload],
    ApiResponse<Site>
  >,
) => {
  return useQuery(
    ["siteService.fetchSite", payload],
    () => firstValueFrom(siteService.fetchSite(payload)),
    {
      ...options,
    },
  );
};
