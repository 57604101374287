import { Observable } from "rxjs";

import { ApiResponse, post, get } from "lib/api";
import { domainService } from "services/Domain";

import * as serviceManagerConstants from "constants/service-manager";

export class ServiceManagerService {
  appApiVersion: string;
  appWebVersion: string;

  getCurrentVersion(config: { serviceName: string }): Observable<
    ApiResponse<{
      serviceName: string;
      version: string;
    }>
  > {
    return post<
      ApiResponse<{
        serviceName: string;
        version: string;
      }>
    >(
      `${domainService.apiRoot}/${serviceManagerConstants.SERVICE_MANAGER}/${serviceManagerConstants.CURRENT_VERSION}`,
      {
        body: {
          serviceName: config.serviceName,
        },
      },
    );
  }

  getLatestVersion(config: { serviceName: string }): Observable<
    ApiResponse<{
      serviceName: string;
      version: string;
    }>
  > {
    return post<
      ApiResponse<{
        serviceName: string;
        version: string;
      }>
    >(
      `${domainService.apiRoot}/${serviceManagerConstants.SERVICE_MANAGER}/${serviceManagerConstants.LATEST_VERSION}`,
      {
        body: {
          serviceName: config.serviceName,
        },
      },
    );
  }

  updateVersion(config: { serviceName: string; version: string }): Observable<
    ApiResponse<{
      serviceName: string;
      version: string;
      result: "OK" | "ERROR";
    }>
  > {
    return post<
      ApiResponse<{
        serviceName: string;
        version: string;
        result: "OK" | "ERROR";
      }>
    >(
      `${domainService.apiRoot}/${serviceManagerConstants.SERVICE_MANAGER}/${serviceManagerConstants.UPDATE_VERSION}`,
      {
        body: {
          serviceName: config.serviceName,
        },
      },
    );
  }

  checkMigrations(): Observable<
    ApiResponse<{
      requireMigration: boolean;
      files: string[];
    }>
  > {
    return get<
      ApiResponse<{
        requireMigration: boolean;
        files: string[];
      }>
    >(
      `${domainService.apiRoot}/${serviceManagerConstants.SERVICE_MANAGER}/${serviceManagerConstants.CHECK_MIGRATIONS}`,
    );
  }

  runMigrations(): Observable<
    ApiResponse<{
      action: string;
    }>
  > {
    return post<
      ApiResponse<{
        action: string;
      }>
    >(
      `${domainService.apiRoot}/${serviceManagerConstants.SERVICE_MANAGER}/${serviceManagerConstants.RUN_MIGRATIONS}`,
      {
        body: {},
      },
    );
  }
}

export const serviceManagerService = new ServiceManagerService();
