import { ApiResponse } from "lib/api";
import { firstValueFrom } from "rxjs";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { MutationOptions, QueryOptions } from "lib/request";

import { serviceManagerService } from "services/ServiceManager";

export const useGetCurrentVersion = (
  payload: { serviceName: string },
  options?: QueryOptions<
    ["serviceManagerService.getCurrentVersion", typeof payload],
    {
      serviceName: string;
      version: string;
    }
  >,
) => {
  return useQuery(
    ["serviceManagerService.getCurrentVersion", payload],
    () =>
      firstValueFrom(serviceManagerService.getCurrentVersion(payload)).then(
        (response) => response.data,
      ),
    {
      ...options,
      retry: false,
    },
  );
};

export const useGetLatestVersion = (
  payload: { serviceName: string },
  options?: QueryOptions<
    ["serviceManagerService.getLatestVersion", typeof payload],
    {
      serviceName: string;
      version: string;
    }
  >,
) => {
  return useQuery(
    ["serviceManagerService.getLatestVersion", payload],
    () =>
      firstValueFrom(serviceManagerService.getLatestVersion(payload)).then(
        (response) => response.data,
      ),
    {
      ...options,
      retry: false,
    },
  );
};

export const useUpdateVersion = (
  options: MutationOptions<{
    serviceName: string;
    version: string;
  }>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload) =>
      firstValueFrom(serviceManagerService.updateVersion(payload)),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries(
          "serviceManagerService.getCurrentVersion",
        );
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};

export const useCheckMigrations = (
  options?: QueryOptions<
    ["serviceManagerService.checkMigrations"],
    {
      requireMigration: boolean;
      files: string[];
    }
  >,
) => {
  return useQuery(
    ["serviceManagerService.checkMigrations"],
    () =>
      firstValueFrom(serviceManagerService.checkMigrations()).then(
        (response) => response.data,
      ),
    {
      ...options,
    },
  );
};

export const useRunMigrations = (options: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => firstValueFrom(serviceManagerService.runMigrations()),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        void queryClient.invalidateQueries(
          "serviceManagerService.checkMigrations",
        );
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};
