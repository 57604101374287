import { SiteSummary } from "state/site/interface";

import { AppConfig } from "config";
import { DefaultSite } from "theme/default";
import { deviceService } from "services/Capacitor";
import { domainService } from "services/Domain";
import { sessionService } from "state/session/service";
import { siteService } from "state/site/service";
import { storageService } from "services/Capacitor";

export const getMobileDomainFromStorage = async (payload: {
  isMobile: boolean;
}) => {
  try {
    if (!payload.isMobile)
      throw new Error(
        "Error: cannot use mobile domain from storage - not capacitor app",
      );
    const getResult = await storageService.get({
      key: AppConfig.siteSelectedLocalStorage,
    });
    const selectedSiteString = getResult.value;
    const selectedSite = selectedSiteString
      ? (JSON.parse(selectedSiteString) as SiteSummary)
      : null;

    if (selectedSite?.domain) {
      domainService.setDomain(selectedSite.domain);
      return selectedSite.domain;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const bootstrapApplication = async (config: {
  onSuccess: () => void;
  onError: (e) => void;
}) => {
  const { onSuccess, onError } = config;
  const domain = await getMobileDomainFromStorage({
    isMobile: deviceService.isMobile,
  });

  if (!domain) {
    siteService.upsertSiteInformation(DefaultSite);
    sessionService.updateMainPagination({
      siteId: DefaultSite.id,
    });
    siteService.setActive(DefaultSite.id);
    onSuccess();
  }

  try {
    onSuccess();
  } catch (e) {
    onError(e);
  }
};
