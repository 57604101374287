import { firstValueFrom } from "rxjs";

import { useMutation, useQuery } from "react-query";

import { ApiResponse } from "lib/api";
import { MutationOptions, QueryOptions } from "lib/request";
import { setupService } from "services/Setup";

export const useSetupRequired = (
  payload: null,
  options?: QueryOptions<
    ["setupService.checkIfRequired", typeof payload],
    ApiResponse<null>
  >,
) => {
  return useQuery(
    ["setupService.checkIfRequired", payload],
    () => firstValueFrom(setupService.checkIfRequired()),
    {
      ...options,
    },
  );
};

export const useSetupCreateDb = (
  options: MutationOptions<{
    host: string;
    siteName: string;
    name: string;
    language: string;
    email: string;
    password: string;
    confirm: string;
  }>,
) => {
  return useMutation(
    (payload) => {
      return firstValueFrom(setupService.createDb(payload));
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};
