import { ACL } from "wordparrot-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ForumIcon from "@mui/icons-material/Forum";
import HomeIcon from "@mui/icons-material/Home";
import ImageIcon from "@mui/icons-material/Image";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PowerIcon from "@mui/icons-material/Power";
import SettingsIcon from "@mui/icons-material/Settings";

import * as routingConstants from "lib/routing";

export interface LinkOption {
  label: string;
  to?: string;
  hide?: boolean;
  link?: string;
  icon?: string;
  permissions?: ACL[];
  openNewTab?: boolean;
  component?: any;
}

export interface SidebarSection {
  id: string;
  name: string;
  description: string;
  route: string;
  permissions?: ACL[];
  pathMatch?: string[];
  tabIndex?: number;
  hidden?: boolean;
}

export const sections: SidebarSection[] = [
  {
    id: "home",
    name: "Home",
    route: routingConstants.ROUTE_DASHBOARD_MAIN,
    description: "",
  },
  {
    id: "pipeline",
    name: "My Projects",
    route: routingConstants.ROUTE_PROJECTS_PREFIX,
    description: "",
    pathMatch: [
      routingConstants.PIPELINES,
      routingConstants.PROMPTS,
      routingConstants.WEBHOOKS,
      routingConstants.LISTENERS,
      routingConstants.FORMS,
    ],
  },
  {
    id: "featured",
    hidden: true,
    name: "Featured Content",
    route: routingConstants.ROUTE_FEATURED_GROUP_LIST_LATEST,
    description: "Subscribe to content from the community.",
  },
  {
    id: "entry",
    hidden: true,
    name: "Entries",
    route: routingConstants.ROUTE_ENTRY_LIST,
    description: "Post to your Wordparrot custom site.",
  },
  {
    id: "plugin",
    name: "Plugins",
    route: routingConstants.ROUTE_PLUGINS_LIST,
    description: "Post to your Wordparrot custom site.",
  },
  {
    id: "credentials",
    name: "Credentials",
    route: routingConstants.ROUTE_CREDENTIALS_LIST,
    description:
      "Store API keys to your favorite third party platform, like Twitter or Reddit.",
    pathMatch: [routingConstants.CREDENTIALS],
  },
  {
    id: "storage",
    name: "Storage",
    route: routingConstants.ROUTE_REPOSITORY_LIST,
    description:
      "Store social media posts, raw data, file, and other things generated by your pipelines.",
    pathMatch: [routingConstants.REPOSITORIES],
  },
  {
    id: "directory",
    hidden: true,
    name: "File Directory",
    route: routingConstants.ROUTE_DIRECTORY_PREFIX,
    description: "It's general settings.",
  },
  {
    id: "media",
    name: "Media",
    route: routingConstants.ROUTE_MEDIA_IMAGES,
    description:
      "Add images, customize site banners, and configure media settings.",
    hidden: true,
  },
  {
    id: "users",
    name: "Users",
    route: routingConstants.ROUTE_USER_LIST,
    description: "Control access to your Wordparrot custom site.",
  },
  {
    id: "theme",
    name: "Theme",
    route: routingConstants.ROUTE_DASHBOARD_THEME,
    description:
      "Customize colors, banners, logos, and other appearance for your custom site.",
  },
  {
    id: "settings",
    name: "Settings",
    route: routingConstants.ROUTE_DASHBOARD_SETTINGS,
    description:
      "Configure your public profile and get information about Wordparrot's platform.",
  },
  {
    id: "logout",
    name: "Logout",
    route: routingConstants.ROUTE_LOGOUT,
    description: "",
  },
];

export const dynamicIcon = (
  section: SidebarSection,
  classes,
  isHighlighted,
) => {
  switch (section.id) {
    case "home":
      return (
        <HomeIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "entry":
      return (
        <PostAddIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "featured":
      return (
        <ForumIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "media":
      return (
        <ImageIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "pipeline":
      return (
        <AccountTreeIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "plugin":
      return (
        <PowerIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "storage":
      return (
        <FontAwesomeIcon
          icon={faDatabase}
          style={{
            padding: 5,
            height: 15,
            width: 15,
          }}
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "credentials":
      return (
        <KeyIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "settings":
      return (
        <SettingsIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "theme":
      return (
        <ColorLensIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "users":
      return (
        <PersonIcon
          className={clsx(classes.icon, {
            [classes.highlightedIcon]: isHighlighted,
          })}
        />
      );
    case "logout":
      return <LogoutIcon className={clsx(classes.icon)} />;
    default:
      return <></>;
  }
};

export const sectionTree: Record<string, SidebarSection> = sections.reduce(
  (accumulator, counter) => {
    if (counter.id) {
      accumulator[counter.id] = counter;
    }
    return accumulator;
  },
  {},
);
