export const SERVICE_NAME = "app-service-manager";
export const SERVICE_MANAGER = "service-manager";
export const CURRENT_VERSION = "current-version";
export const LATEST_VERSION = "latest-version";
export const UPDATE_VERSION = "update-version";

export const APP_API_SERVICE = "app-api";
export const APP_WEB_SERVICE = "app-web";
export const APP_NGINX_SERVICE = "app-nginx";
export const APP_SANDBOX_SERVICE = "app-sandbox";
export const APP_SERVICE_MANAGER_SERVICE = "app-service-manager";

export const CHECK_MIGRATIONS = "check-migrations";
export const RUN_MIGRATIONS = "run-migrations";

export const MIGRATION_RESULT_NOT_RUN = "migration_not_run";
export const MIGRATION_RESULT_SUCCESS = "migration_success";
export const MIGRATION_RESULT_ERROR = "migration_error";
