import { AuthorizedProfile } from "state/site/interface";
import { firstValueFrom } from "rxjs";
import { useQuery } from "react-query";

import { QueryOptions } from "lib/request";
import { authService } from "services/Auth";

export const useFetchAuth = (
  options?: QueryOptions<["authService.fetchAuth"], Promise<AuthorizedProfile>>,
) => {
  return useQuery(
    ["authService.fetchAuth"],
    () => firstValueFrom(authService.fetchAuth()),
    {
      ...options,
    },
  );
};
