import isFunction from "lodash-es/isFunction";

export type BreadcrumbType = {
  title: string;
  entityType: string;
  identifier: string;
  bold: boolean;
};

class BreadcrumbService {
  map: Record<string, BreadcrumbType> = {};

  hasInMap(id: string): boolean {
    return !!this.map[id];
  }

  get(id: string): string {
    const match = this.map[id];

    if (!match) {
      return id;
    }

    return match.title;
  }

  set(
    entities: any[],
    entityType: string,
    idProperty = "id",
    render?: (entity: any) => string,
  ): void {
    entities.forEach((entity) => {
      const entityMeta: BreadcrumbType = {
        title: isFunction(render)
          ? render(entity)
          : entity.title || entity[idProperty],
        entityType,
        identifier: entity[idProperty] || entity.id,
        bold: true,
      };

      this.map[entityMeta.identifier] = entityMeta;
    });
  }
}

export const breadcrumbService = new BreadcrumbService();
